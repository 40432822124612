<template>
    <button
        :class="[
            $style.button,
            fluid === true || fluid === '' ? $style.fluid : '',
            loading ? $style.loading : '',
            $style[color]
        ]"
        :type="$props.type"
        v-on:click="click()"
    >
        <span :class="$style.content">

            <b-icon v-if="$props.icon" :icon="$props.icon" :class="$style.icon"></b-icon>

            {{ $props.label }}

            <slot></slot>

            <Loader v-if="loading" :class="$style.loader" text="" color="white" size="small" />

        </span>
    </button>
</template>

<script>

import Loader from '@/components/Loader'

export default {

    name: 'Button',

    components: {
        Loader
    },

    props: {

        type: {
            default: 'button'
        },

        label: {
            default: 'Clique aqui'
        },

        icon: {
            default: null
        },

        color: {
            default: 'primary'
        },

        fluid: {
            default: false
        },

        loading: {
            default: false
        },

        to: {
            default: null
        }

    },

    methods: {

        click() {

            if(!this.loading) {
                
                if (this.$props.to) {

                    this.$router.push(this.$props.to);
                    return;

                }
                
                this.$emit('click');

            }

        }

    }

}

</script>

<style lang="scss" module>

.button {

    background: var(--theme-color-1);
    border: 0 none;
    border-radius: 3px;
    color: #fff;
    margin: 0 1rem 1rem 0;
    padding: 10px 15px;
    position: relative;

    &:after {

        background-color: rgba(0, 0, 0, .1);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        // opacity: 0;
        pointer-events: none;
        position: absolute;
        transform: scaleY(0);
        transition: all .1s linear;
        top: 0;
        width: 100%;
        z-index: 1;

    }

    &:hover:after {
        
        opacity: 1;
        transition: all .1s linear;
        transform: scaleY(1);

    }

    &.secondary {

        background: var(--bg-color);
        color: #000;

    }
    
    &.success {
        background: #28a745;
    }

    &.danger {
        background: #dc3545;
    }

    &.warning {
        background: #ffc107;
        color: #000;
    }

    &.info {
        background: #17a2b8;
    }

    &.light {
        background: #f8f9fa;
    }

    &.dark {
        background: #343a40;
        color: #fff;
    }


}

.content {

    position: relative;
    z-index: 10;

}

.icon {
    
    margin-right: .5rem;

}

.fluid {

    margin: 0;
    width: 100%;

    .icon {

        margin-left: -1rem;

    }

}

.loading {

    color: transparent;
    cursor: wait;
    pointer-events: none;

    .loader {

        left : 50%;
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;

    }

}

</style>