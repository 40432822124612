<template>
    <div :class="$style.inputContainer">
        <slot />
    </div>
</template>

<script>

export default {

    name: 'InputContainer'

}

</script>

<style lang="scss" module>

.inputContainer {

    padding-bottom: 1rem;

}

</style>