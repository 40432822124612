<template>
    <InputContainer>

        <Label :for="id" :text="$props.label" />

        <select
            v-if="!isLoading"
            :id="id"
            :class="$style.input"
            :value="value"
            v-on:change="onChange($event.target.value)"
            :required="required"
            :readonly="readonly"
            :disabled="readonly"
        >
            <option
                v-for="(option, index) in getData"
                :key="index"
                :value="option.value"
                :selected="option.value == value"
            >{{ option.label }}</option>
        </select>

        <select v-if="isLoading" :id="id" :class="$style.input" disabled>
            <option value="">Carregando...</option>
        </select>

    </InputContainer>
</template>

<script>

import { uuid } from 'vue-uuid';

import InputContainer from './InputContainer.vue'
import Label from './Label.vue'

export default {

    name: 'Dropdown',

    components: {
        InputContainer,
        Label
    },

    props: {

        label: {
            default: null
        },

        data: {
            default: null
        },

        loading: {
            type: Boolean,
            default: false
        },

        value: {
            default: null
        },

        required: {
            type: Boolean,
            default: false
        },

        readonly: {
            type: Boolean,
            default: false
        },

        load: {
            default: null
        }

    },

    watch: {

        data: [{
            handler: 'updateLocalData'
        }],

        loading: [{
            handler: 'updateLocalData'
        }],

    },

    data() {
        return {

            id: uuid.v4(),

            localLoading: false,

            localData: [],

        }
    },

    computed: {

        isLoading() {

            return this.$props.loading || this.localLoading

        },

        getData() {

            var data = this.localData;

            if(!this.$props.required)
                data.unshift({
                    label: 'Selecione...',
                    value: ''
                });

            return data;

        }

    },

    mounted() {

        if(this.$props.load)
            this.loadData(this.$props.load);

        this.localData = this.$props.data;

    },

    methods: {

        updateLocalData() {

            this.localData = this.$props.data;

            this.emitInput();

        },

        emitInput() {
            
            if(this.getData.length)

                if(this.value)
                    this.$emit('input', this.value);

                else
                    this.$emit('input', this.getData[0].value);
            
            else
                this.$emit('input', null);

        },

        loadData(path) {

            this.localLoading = true;

            this.$http.get(`${this.$store.getters.api}${path}`)
                .then(response => {

                    this.localData = response.data.data;

                    this.emitInput();

                })
                .catch(() => {
                    //this.error = true;
                })
                .finally(() => {
                    this.localLoading = false;
                });

        },

        onChange(val) {
            
            this.$emit('input', val);

        }

    }

}

</script>

<style lang="scss" module>

@import './input.scss';

</style>