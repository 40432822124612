<template>
    <form :class="[$style.form, { extraClass: true }]" v-on:submit.prevent="submit">
        <slot />
    </form>
</template>

<script>

export default {

    name: 'Form',

    props: {

        loading: {
            default: false
        }

    },

    methods: {

        submit() {

            if(!this.loading)
                this.$emit('submit');

        }

    }

}

</script>

<style lang="scss" module>

.form {

    padding-bottom: 1rem;

}

</style>