<template>
    <label :class="$style.label" :for="$props.for">
        <b-icon v-if="$props.icon" :icon="$props.icon" />
        {{ $props.text }}
    </label>
</template>

<script>

export default {

    name: 'Label',

    props: {

        for: {
            default: null
        },

        text: {
            default: ' '
        },

        icon: {
            default: null
        }

    }
}

</script>

<style lang="scss" module>

.label {

    display: block;
    margin: 0 0 .3rem 0;

    &:after {

        content: '.';
        opacity: 0

    }

}

</style>